import request from '@/utils/request';
export function getPropList() {
  return request({
    url: '/data-dictionary/prop/list',
    method: 'get'
  });
}
export function getOptionList(data) {
  return request({
    url: '/data-dictionary/option/list',
    method: 'post',
    data: data
  });
}
export function getOptionDetail(propId, optionId) {
  return request({
    url: '/data-dictionary/option/detail',
    method: 'get',
    params: {
      propId: propId,
      optionId: optionId
    }
  });
}
export function saveOption(data) {
  return request({
    url: '/data-dictionary/option/save',
    method: 'post',
    data: data
  });
}
export function deleteOption(optionId) {
  return request({
    url: '/data-dictionary/option/delete',
    method: 'delete',
    params: {
      optionId: optionId
    }
  });
}
export function getOptionParentList(data) {
  return request({
    url: '/data-dictionary/option/parent/list',
    method: 'post',
    data: data
  });
}
export function batchSaveOption(data) {
  return request({
    url: '/data-dictionary/option/batch-save',
    method: 'post',
    data: data
  });
}