var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.propList.length == 0
        ? _c("span", [_vm._v("\n    字典管理未配置\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.propList.length > 0
            ? _c(
                "el-select",
                {
                  staticStyle: { width: "250" },
                  attrs: {
                    placeholder: _vm.$t("label.pleaseSelect"),
                    clearable: "",
                    filterable: "",
                    "value-key": "value",
                  },
                  on: { change: _vm.handleChooseProp },
                  model: {
                    value: _vm.selectedProp,
                    callback: function ($$v) {
                      _vm.selectedProp = $$v
                    },
                    expression: "selectedProp",
                  },
                },
                _vm._l(_vm.propList, function (o) {
                  return _c("el-option", {
                    key: o.value,
                    attrs: { label: o.label, value: o },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedProp["value"] != null
        ? _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-form",
                    { ref: "listQuery", attrs: { model: _vm.listQuery.param } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: _vm.optionTableColumns.filter(function (
                            item
                          ) {
                            return item.code == "c_code"
                          })[0].label,
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getOptionList($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.param["c_code"],
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.param, "c_code", $$v)
                          },
                          expression: "listQuery.param['c_code']",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: _vm.optionTableColumns.filter(function (
                            item
                          ) {
                            return item.code == "c_label"
                          })[0].label,
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getOptionList($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.param["c_label"],
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.param, "c_label", $$v)
                          },
                          expression: "listQuery.param['c_label']",
                        },
                      }),
                      _vm._v(" "),
                      _vm.withParent == 1
                        ? _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "200px" },
                            attrs: {
                              placeholder: _vm.optionTableColumns.filter(
                                function (item) {
                                  return item.code == "c_parent_code"
                                }
                              )[0].label,
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.getOptionList($event)
                              },
                            },
                            model: {
                              value: _vm.listQuery.param["c_parent_code"],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.listQuery.param,
                                  "c_parent_code",
                                  $$v
                                )
                              },
                              expression: "listQuery.param['c_parent_code']",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.withParent == 1
                        ? _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "200px" },
                            attrs: {
                              placeholder: _vm.optionTableColumns.filter(
                                function (item) {
                                  return item.code == "c_parent_label"
                                }
                              )[0].label,
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.getOptionList($event)
                              },
                            },
                            model: {
                              value: _vm.listQuery.param["c_parent_label"],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.listQuery.param,
                                  "c_parent_label",
                                  $$v
                                )
                              },
                              expression: "listQuery.param['c_parent_label']",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.getOptionList },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.search")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-refresh" },
                          on: { click: _vm.handleResetOptionList },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.reset")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-edit" },
                          on: { click: _vm.handleAddOption },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.add")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-download" },
                          on: { click: _vm.handleDownload },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.export")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-upload2" },
                          on: { click: _vm.handleImport },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.import")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-refresh" },
                          on: { click: _vm.handleRefreshDD },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.refreshDD")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "optionListTable",
                  attrs: {
                    data: _vm.optionListData,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    "row-class-name": _vm.tableRowClassName,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_display_order"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_display_order"
                      })[0].code,
                      width: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_display_order"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_code"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_code"
                      })[0].code,
                      width: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_code"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_label"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_label"
                      })[0].code,
                      width: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_label"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.withParent == 1
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: _vm.optionTableColumns.filter(function (item) {
                            return item.code == "c_parent_code"
                          })[0].label,
                          prop: _vm.optionTableColumns.filter(function (item) {
                            return item.code == "c_parent_code"
                          })[0].code,
                          width: _vm.optionTableColumns.filter(function (item) {
                            return item.code == "c_parent_code"
                          })[0].width,
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.withParent == 1
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: _vm.optionTableColumns.filter(function (item) {
                            return item.code == "c_parent_label"
                          })[0].label,
                          prop: _vm.optionTableColumns.filter(function (item) {
                            return item.code == "c_parent_label"
                          })[0].code,
                          width: _vm.optionTableColumns.filter(function (item) {
                            return item.code == "c_parent_label"
                          })[0].width,
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_using_records"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_using_records"
                      })[0].code,
                      width: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_using_records"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_creator"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_creator"
                      })[0].code,
                      width: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_creator"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_create_time"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_create_time"
                      })[0].code,
                      "min-width": _vm.optionTableColumns.filter(function (
                        item
                      ) {
                        return item.code == "c_rec_create_time"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row["c_rec_create_time"]
                                ? _c("span", [
                                    _c("i", { staticClass: "el-icon-time" }),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.getLocalTime(
                                      scope.row["c_rec_create_time"]
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3435130623
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_revisor"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_revisor"
                      })[0].code,
                      width: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_revisor"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_revise_time"
                      })[0].label,
                      prop: _vm.optionTableColumns.filter(function (item) {
                        return item.code == "c_rec_revise_time"
                      })[0].code,
                      "min-width": _vm.optionTableColumns.filter(function (
                        item
                      ) {
                        return item.code == "c_rec_revise_time"
                      })[0].width,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row["c_rec_revise_time"]
                                ? _c("span", [
                                    _c("i", { staticClass: "el-icon-time" }),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.getLocalTime(
                                      scope.row["c_rec_revise_time"]
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3172313663
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("button.operation"),
                      align: "center",
                      fixed: "right",
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" },
                                  ],
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEditOption(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("button.edit")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              row.c_using_records == null ||
                              row.c_using_records == 0
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        { name: "waves", rawName: "v-waves" },
                                      ],
                                      attrs: {
                                        type: "danger",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteOption(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("button.delete")) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3029392373
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getOptionList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "60%",
            visible: _vm.dialogVisible,
            title: this.selectedProp.label,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "margin-top": "0px", align: "center" },
              attrs: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "optionEdit",
                      attrs: {
                        model: _vm.optionEditForm,
                        "label-width": "150px",
                      },
                    },
                    [
                      _vm.withParent == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.optionTableColumns.filter(function (
                                  item
                                ) {
                                  return item.code == "c_parent_id"
                                })[0].label,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { "min-width": "300px" },
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: this.selectedOptionId != 0,
                                  },
                                  model: {
                                    value: _vm.optionEditForm["c_parent_id"],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.optionEditForm,
                                        "c_parent_id",
                                        $$v
                                      )
                                    },
                                    expression: "optionEditForm['c_parent_id']",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.parentOptionListData,
                                    function (o) {
                                      return [
                                        _c("el-option", {
                                          key: o.value,
                                          attrs: {
                                            label: o.label,
                                            value: o.value,
                                          },
                                        }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.optionTableColumns.filter(function (
                              item
                            ) {
                              return item.code == "c_code"
                            })[0].label,
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.optionTableColumns.filter(
                                function (item) {
                                  return item.code == "c_code"
                                }
                              )[0].label,
                              clearable: "",
                              disabled: this.selectedOptionId != 0,
                            },
                            model: {
                              value: _vm.optionEditForm["c_code"],
                              callback: function ($$v) {
                                _vm.$set(_vm.optionEditForm, "c_code", $$v)
                              },
                              expression: "optionEditForm['c_code']",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.optionTableColumns.filter(function (
                              item
                            ) {
                              return item.code == "c_label"
                            })[0].label,
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.optionTableColumns.filter(
                                function (item) {
                                  return item.code == "c_label"
                                }
                              )[0].label,
                              clearable: "",
                            },
                            model: {
                              value: _vm.optionEditForm["c_label"],
                              callback: function ($$v) {
                                _vm.$set(_vm.optionEditForm, "c_label", $$v)
                              },
                              expression: "optionEditForm['c_label']",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.optionTableColumns.filter(function (
                              item
                            ) {
                              return item.code == "c_display_order"
                            })[0].label,
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.optionTableColumns.filter(
                                function (item) {
                                  return item.code == "c_display_order"
                                }
                              )[0].label,
                              clearable: "",
                            },
                            model: {
                              value: _vm.optionEditForm["c_display_order"],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.optionEditForm,
                                  "c_display_order",
                                  $$v
                                )
                              },
                              expression: "optionEditForm['c_display_order']",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                { name: "waves", rawName: "v-waves" },
                              ],
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSaveOption },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("button.save")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                { name: "waves", rawName: "v-waves" },
                              ],
                              staticClass: "filter-item",
                              on: { click: _vm.closeDialog },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("button.close")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-row"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "60%",
            visible: _vm.batchDialogVisible,
            title: this.selectedProp.label,
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "margin-top": "0px", align: "center" },
              attrs: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "batchOptionSave",
                      attrs: {
                        model: _vm.batchOptionSaveForm,
                        "label-width": "150px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "margin-bottom": "20px" } },
                        [
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.withCode,
                                  callback: function ($$v) {
                                    _vm.withCode = $$v
                                  },
                                  expression: "withCode",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  仅输入名称，编码由系统自动创建\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.withCode,
                                  callback: function ($$v) {
                                    _vm.withCode = $$v
                                  },
                                  expression: "withCode",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  输入编码+名称\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _vm.withParent == 0 && _vm.withCode == 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    padding: "0px 0px 0px 20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              每行代表一个字典项，如果您想添加多个字典项，请以多行方式添加，空白行会被忽略。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    "\n              如果原字典项已有该名称，则系统会忽略它，不会重复添加。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.withParent == 0 && _vm.withCode == 0
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 10,
                                  placeholder: "请输入名称",
                                },
                                model: {
                                  value: _vm.batchOptionSaveForm["textarea"],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.batchOptionSaveForm,
                                      "textarea",
                                      $$v
                                    )
                                  },
                                  expression: "batchOptionSaveForm['textarea']",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.withParent == 0 && _vm.withCode == 1
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    padding: "0px 0px 0px 20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              每行代表一个字典项，如果您想添加多个字典项，请以多行方式添加，空白行会被忽略。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    '\n              请输入"编码+名称"组合，编码和名称中间输入一个tab键。'
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "（从Excel复制过来的相邻两列，直接粘贴过来，会自动带上tab，不需要另外处理）"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    "\n              如果原字典项已有该编码，则系统会更新它的名称；如果原字典项没有该编码，则系统会新增。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.withParent == 0 && _vm.withCode == 1
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 10,
                                  placeholder: "请输入编码<tab>名称",
                                },
                                model: {
                                  value: _vm.batchOptionSaveForm["textarea"],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.batchOptionSaveForm,
                                      "textarea",
                                      $$v
                                    )
                                  },
                                  expression: "batchOptionSaveForm['textarea']",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.withParent == 1 && _vm.withCode == 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    padding: "0px 0px 0px 20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              每行代表一个字典项，如果您想添加多个字典项，请以多行方式添加，空白行会被忽略。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    '\n              请输入"名称+父名称"组合，名称和父名称中间输入一个tab键。'
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "（从Excel复制过来的相邻两列，直接粘贴过来，会自动带上tab，不需要另外处理）"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    "\n              请确保父名称已经事先添加进系统，否则系统将报错。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    "\n              如果原字典项已有该名称，则系统不会重复添加，只会更新父名称。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.withParent == 1 && _vm.withCode == 0
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 10,
                                  placeholder: "请输入名称<tab>父名称",
                                },
                                model: {
                                  value: _vm.batchOptionSaveForm["textarea"],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.batchOptionSaveForm,
                                      "textarea",
                                      $$v
                                    )
                                  },
                                  expression: "batchOptionSaveForm['textarea']",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.withParent == 1 && _vm.withCode == 1
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    padding: "0px 0px 0px 20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              每行代表一个字典项，如果您想添加多个字典项，请以多行方式添加，空白行会被忽略。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    '\n              请输入"编码+名称+父编码"组合，编码、名称和父编码中间输入一个tab键。'
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "（从Excel复制过来的相邻三列，直接粘贴过来，会自动带上tab，不需要另外处理）"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    "\n              请确保父编码已经事先添加进系统，否则系统将报错。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    "\n              如果原字典项已有该编码，则系统会更新它的名称及父编码；如果原字典项没有该编码，则系统会新增。"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.withParent == 1 && _vm.withCode == 1
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 10,
                                  placeholder: "请输入编码<tab>名称<tab>父编码",
                                },
                                model: {
                                  value: _vm.batchOptionSaveForm["textarea"],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.batchOptionSaveForm,
                                      "textarea",
                                      $$v
                                    )
                                  },
                                  expression: "batchOptionSaveForm['textarea']",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "20px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                { name: "waves", rawName: "v-waves" },
                              ],
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: { click: _vm.handleBatchSaveOption },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("button.save")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                { name: "waves", rawName: "v-waves" },
                              ],
                              staticClass: "filter-item",
                              on: { click: _vm.closeBatchDialog },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("button.close")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-row"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }